import { render } from 'react-dom';
import React from 'react';
import Login from './pages/Login';
import { UserStorage } from './utils/user-storage';
import App from './App';

const root = document.getElementById('root')!;
UserStorage.getAccount()
  .then(user => {

    if (user) {
      render(<App />, root);

    } else {
      render(<Login />, root);
    }
  })
  .catch(() => {
    render(<Login />, root);
  });
